
.page_divider {
    margin: 30px !important;
}

.home_images_wrapper {
    text-align: right;
}

.home_dress {
    height: 380px;
    right: 20%;
    margin-top: -345px;
    position: relative;
}

.slider {
    background-color: #dedede !important;
}

@media screen and (max-width: 800px) {
    .home_images_wrapper {
        text-align: center;
    }
    .home_dress {
        height: 340px;
        right: 0;
        margin: 0 auto;
        margin-top: -360px;
    }
}

.home_grid {
    max-width: 100vw !important;
}
.home_course {
    width: calc(100% - 60px);
    padding: 16px 30px;
}

.courses_image {
    text-align: left;
    width: 175px;
    height: 175px;
    background-color: rgb(207, 207, 207);
}

.courses_card {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
}

.courses_details {
    display: flex;
}
.courses_image {
    width: 180px;
    min-width: 180px;
    height: 180px;
    margin-top: 20px;
}

.aboutme_avatar {
    width: 150px;
    height: 150px;
    margin: 30px auto 0 auto;
    border-radius: 100%;
}

.course_dates_table {
    width: 100%;
}

.MuiFilledInput-root {
    background-color: #f0f0f0b0 !important;
}

.contact_form{
    padding: 6px;
}

.snackbar_wrapper {
    z-index: 20;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #ffffff !important;
    border-radius: 0 !important;
    box-shadow: 1px -5px 10px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.snackbar_content {
    max-width: 1000px;
    margin: auto;
    padding: 16px;
}
.snackbar_action {
    max-width: 1000px;
    margin: auto;
    padding: 16px;
    text-align: right;
}

.googlemaps {
    width: 100%;
    height: 250px;
    border: none;
}

.image_overlay {
    width: 100%;
    height: 100%;
    font-size: large;
    background-color: #ffffff7c;
    display: flex;
}

.image_overlay_icon {
    margin: auto;
}